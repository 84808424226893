@font-face {
  font-family: 'Roboto';
  src: url('/assets/fonts/Roboto-Bold.ttf');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('/assets/fonts/Roboto-Medium.ttf');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('/assets/fonts/Roboto-Regular.ttf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/assets/fonts/Montserrat-Black.ttf');
}

@font-face {
  font-family: 'Quicksand';
  src: url('/assets/fonts/Quicksand-Bold.ttf');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

#adverticum_r_above_container iframe {
  margin: 0 auto !important;
}

#adverticum_r_above_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

#adverticum_r_above_container.hide-when-not-loaded:not(.loaded) {
  display: none;
}

#__next #adverticum_r_above_container.loaded ~ #page-content {
  margin: 0 auto 0;
}

#adverticum_adsense_container {
  flex-direction: column;
  margin-bottom: 20px;
}

#adverticum_adsense_container.loaded::before {
  content: "Hirdetés";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: inherit;
  font-size: 0.75rem;
  padding-left: 8px;
  padding-right: 8px;
  height: 20px;
  color: rgba(0, 0, 0, 0.38);
  z-index: 9;
}

#adverticum_adsense_container.loaded .adverticum-label {
  display: none;
}

#open-cookie-settings {
  cursor: pointer;
}

.vertical-background-wrapper {
  position: relative;
  background-repeat: repeat-x, repeat-x;
  background-size: 288px 150px;
  z-index: 1;
}

.vertical-background-wrapper:after {
  content: '';
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  transform: translate(-50%, 0);
  margin-left: 50%;
}

.vertical-background-wrapper:before {
  content: '';
  height: 100px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

html {
  scroll-behavior: smooth;
}

@media screen and (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}

#adverticum_calculator_container .adverticum-label {
  bottom: 0;
  top: initial
}

#adverticum_calculator_container .goa-embedded {
  width: 100%;
}

#adverticum_calculator_container .goa-embedded iframe {
  height: 400px !important;
  width: 100% !important;
  max-width: 656px !important;
}

@media screen and (min-width: 600px) {
  #adverticum_calculator_container .goa-embedded iframe {
    height: 320px !important;
  }

  .vertical-background-wrapper:after {
    width: 100%;
  }
}

